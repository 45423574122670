var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.popup && _vm.show ? _c('div', {
    staticClass: "popup-layer-container",
    style: _vm.popupStyle
  }, [_c('a', {
    staticClass: "popup-layer-body",
    attrs: {
      "href": _vm.popup.url,
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "popup-layer-content",
    domProps: {
      "innerHTML": _vm._s(_vm.popup.content.replace(/\n/g, '<br>'))
    }
  })]), _c('div', {
    staticClass: "popup-layer-foot"
  }, [_c('label', {
    staticClass: "check check-light"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.doNotOpenToday,
      expression: "doNotOpenToday"
    }],
    staticClass: "input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.doNotOpenToday) ? _vm._i(_vm.doNotOpenToday, true) > -1 : _vm.doNotOpenToday
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.doNotOpenToday,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = true,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.doNotOpenToday = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.doNotOpenToday = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.doNotOpenToday = $$c;
        }
      }
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("오늘하루 열지않음")])]), _c('button', {
    staticClass: "button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('span', {
    staticClass: "text"
  }, [_vm._v("닫기")])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }